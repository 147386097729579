import { maxKeywordsPerCondition } from '@main/api/limits';
import type { ListOptions } from '@main/api/plugin/types/api';
import type { DebugFetchResults } from '@main/api/resources/debug';
import type { ApiProjectEntry } from '@main/api/resources/projects';
import {
    type AggregatableField,
    type ApiSearchQuery,
    ApiSearchQueryDebugAggregations,
    ApiSearchQueryDefaultAggregations,
    type ApiSearchResult,
    type CountedAggregation,
    createQuery,
    deleteQuery,
    explainQuery,
    type FetchApiProjectEntryResultsReturn,
    type FetchApiSearchResultsReturn,
    fetchProjectEntries,
    fetchQueries,
    fetchQuery,
    fetchResults,
    type NewApiSearchQuery,
    type SearchCondition,
    type SearchConditionKeyword,
    type SearchConditionType,
    type SearchFilter,
    type SearchResultsAugmentationMeta,
    updateDefaultQuery,
} from '@main/api/resources/search';
import type {
    AddFilterEvent,
    RemoveFilterEvent,
} from '@main/components/App/Projects/Features/ProjectSearch.vue';
import { SearchQuery } from '@main/domain/search/SearchQuery';
import { SearchResult } from '@main/domain/search/SearchResult';
import { useTrackingStore } from '@main/store/stores/tracking';
import { patchList } from '@main/utilities/store';
import { acceptHMRUpdate, defineStore } from 'pinia';
import Vue from 'vue';

const MAX_STORED_QUERIES = 20;

type State = {
    generalQueries: ApiSearchQuery[];
    activeGeneralQueryUuid: string | null;
    generalQueryResults: Record<string, Record<number, ApiSearchResult[]>>;

    projectQueries: Record<string, ApiSearchQuery[]>;

    defaultProjectQueries: Record<string, ApiSearchQuery>;
    activeProjectQueryUuids: Record<string, string | null>;
    projectQueryResults: Record<string, Record<number, ApiSearchResult[]>>;
    historicalProjectQueries: Record<string, ApiSearchQuery[]>;

    // Active query uuid mapped to the (possibly) dirty, pending query.
    pendingQuery: Record<string, NewApiSearchQuery | null>;

    projectEntryQueries: Record<string, ApiSearchQuery[]>;
    activeProjectEntryQueryUuids: Record<string, string | null>;
    projectEntryQueryResults: Record<string, Record<number, ApiProjectEntry[]>>;

    resultsAvailable: Record<string, number>;
    resultCompaniesAvailable: Record<string, number>;
    resultsTotals: Record<string, number>;
    resultsPaginations: Record<string, PaginationInfo>;
    resultsAggregations: Record<string, Partial<Record<AggregatableField, CountedAggregation[]>>>;
    resultsAugmentations: Record<string, SearchResultsAugmentationMeta>;

    lastDebug: {
        projectUuid: string;
        queryUuid: string;
        data: DebugFetchResults;
    } | null;
};

export const useSearchStore = defineStore( 'search', {
    state(): State {
        return {
            // General search queries
            // General queries are currently not used and might be removed in the future.
            activeGeneralQueryUuid: null,
            generalQueries: [],
            generalQueryResults: {},

            // Project search queries
            activeProjectQueryUuids: {},
            defaultProjectQueries: {},
            projectQueries: {},
            projectQueryResults: {},

            // TODO: limit these queries with limitStoredQueries, too
            // List of previously performed queries
            historicalProjectQueries: {},

            // Query which reflects active query plus all live changes made
            // by the user until a new search is triggered.
            pendingQuery: {},

            // Project-entry search queries
            activeProjectEntryQueryUuids: {},
            projectEntryQueries: {},
            projectEntryQueryResults: {},

            // Number of search results (primary company together with grouped companies)
            // available per query
            resultsAvailable: {},

            // Number of all companies in all available search results per query
            resultCompaniesAvailable: {},

            // Number of total matching companies per query
            resultsTotals: {},

            // Pagination and aggregations per query
            resultsPaginations: {},

            // Aggregations per query
            resultsAggregations: {},

            // Information of augmented results per query
            resultsAugmentations: {},

            // Debug data for search results that was used last
            lastDebug: null,
        };
    },

    actions: {
        // region Queries
        async createGeneralQuery( query: NewApiSearchQuery ) {
            const resource = await createQuery( query );

            if ( !resource ) {
                throw new Error( 'Failed to create query' );
            }

            this.$patch( ( state ) => limitStoredQueries( state, 'general' ) );

            this.$patch( ( state ) => mutateQuery( state, 'general', true, resource ) );

            return resource.uuid;
        },

        async createProjectQuery(
            query: NewApiSearchQuery,
            projectUuid: string,
            previousQueryUuid?: string,
        ) {
            if ( !query.aggregations || query.aggregations.length === 0 ) {
                query = enrichQueryWithDefaultAggregations( query );
            }

            /**
             * TODO: Remove this block when the taxonomy filters are activated.
             * (Requires new Index to be shipped to production)
             */
            if ( import.meta.env.VITE_ACTIVATE_TAXONOMY_FILTERS === 'true' ) {
                query = enrichQueryWithDebugAggregations( query );
            }

            const resource = await createQuery(
                {
                    ...query,
                    previousQueryUuid,
                },
                projectUuid,
            );

            if ( !resource ) {
                throw new Error( 'Failed to create query' );
            }

            this.$patch( ( state ) => limitStoredQueries( state, 'project', projectUuid ) );
            this.$patch( ( state ) => mutateQuery( state, 'project', true, resource, projectUuid ) );
            this.$patch( ( state ) =>
                patchList( state.historicalProjectQueries[projectUuid], resource, 'uuid', true ),
            );

            void useTrackingStore().sendSearchTriggered( resource.uuid );

            return resource.uuid;
        },

        async createProjectEntryQuery( query: NewApiSearchQuery, projectUuid?: string ) {
            const resource = await createQuery( query, projectUuid );

            if ( !resource ) {
                throw new Error( 'Failed to create query' );
            }

            this.$patch( ( state ) => limitStoredQueries( state, 'projectEntries', projectUuid ) );

            this.$patch( ( state ) =>
                mutateQuery( state, 'projectEntries', true, resource, projectUuid ),
            );

            return resource.uuid;
        },

        async fetchGeneralQuery( queryUuid: string, makeActive = false ) {
            const query = await fetchQuery( queryUuid );

            if ( !query ) {
                throw new Error( 'Failed to load query' );
            }

            this.$patch( ( state ) => mutateQuery( state, 'general', makeActive, query ) );
        },

        async fetchProjectQuery( queryUuid: string, projectUuid: string, makeActive = false ) {
            const query = await fetchQuery( queryUuid, projectUuid );

            if ( !query ) {
                throw new Error( 'Failed to load query' );
            }

            this.$patch( ( state ) => mutateQuery( state, 'project', makeActive, query, projectUuid ) );
        },

        async fetchDefaultProjectQuery( projectUuid: string, makeActive = false ) {
            const query = await fetchQuery( 'default', projectUuid );

            if ( !query ) {
                throw new Error( 'Failed to load query' );
            }

            Vue.set( this.defaultProjectQueries, projectUuid, query );

            this.$patch( ( state ) => mutateQuery( state, 'project', makeActive, query, projectUuid ) );

            return query;
        },

        async updateDefaultProjectQuery( projectUuid: string, queryUuid: string ) {
            await updateDefaultQuery( projectUuid, queryUuid );

            const query = this.projectQueries[projectUuid]?.find( ( { uuid } ) => uuid === queryUuid );

            if ( !query ) {
                throw new Error(
                    'Unexpected state: Data for the new default ' +
                        'query is missing from the store. Make sure to fetch ' +
                        'the query before updating the default.',
                );
            }

            Vue.set( this.defaultProjectQueries, projectUuid, query );
        },

        async fetchProjectQueries( projectUuid: string, options?: ListOptions<ApiSearchQuery> ) {
            const queries = await fetchQueries( projectUuid, options );

            if ( !queries ) {
                throw new Error( 'Failed to fetch queries' );
            }

            Vue.set( this.historicalProjectQueries, projectUuid, queries );
        },

        async fetchProjectEntryQuery( queryUuid: string, projectUuid: string, makeActive = false ) {
            const query = await fetchQuery( queryUuid, projectUuid );

            if ( !query ) {
                throw new Error( 'Failed to load query' );
            }

            this.$patch( ( state ) =>
                mutateQuery( state, 'projectEntries', makeActive, query, projectUuid ),
            );
        },

        async explainLastDebugQuery( companyUuid: string ) {
            if ( !this.lastDebug ) {
                return null;
            }

            return explainQuery(
                this.lastDebug.projectUuid,
                this.lastDebug.queryUuid,
                companyUuid,
                this.lastDebug.data,
            );
        },

        async deleteQuery( queryUuid: string, projectUuid?: string ) {
            return deleteQuery( queryUuid, projectUuid );
        },

        resetActiveGeneralQuery() {
            this.$patch( ( state ) => mutateLastQuery( state, 'general', null ) );
        },

        async resetActiveProjectQuery( projectUuid: string ) {
            const previousQueryUuid = this.activeProjectQueryUuids[projectUuid];

            if ( previousQueryUuid ) {
                void useTrackingStore().sendSearchReset( previousQueryUuid );
            }

            await this.fetchDefaultProjectQuery( projectUuid, true );
        },

        resetActiveProjectEntryQuery( projectUuid: string ) {
            this.$patch( ( state ) => mutateLastQuery( state, 'projectEntries', null, projectUuid ) );
        },

        // endregion

        // region Results
        async fetchGeneralQueryResults(
            queryUuid: string,
            page?: number,
            debug: DebugFetchResults | null = null,
        ) {
            const results = await fetchResults( queryUuid, null, page, 30, debug );

            this.lastDebug = debug ? { data: debug, projectUuid: '', queryUuid } : null;

            this.$patch( ( state ) => mutateResults( state, 'general', queryUuid, null, results ) );
        },

        async fetchProjectQueryResults(
            queryUuid: string,
            projectUuid: string,
            page?: number,
            debug: DebugFetchResults | null = null,
        ) {
            const results = await fetchResults( queryUuid, projectUuid, page, 30, debug );

            this.lastDebug = debug ? { data: debug, projectUuid, queryUuid } : null;

            this.$patch( ( state ) =>
                mutateResults( state, 'project', queryUuid, projectUuid, results ),
            );
        },

        async fetchProjectEntryQueryResults(
            queryUuid: string,
            projectUuid: string,
            page?: number,
            debug: DebugFetchResults | null = null,
        ) {
            const results = await fetchProjectEntries( queryUuid, projectUuid, page, 30, debug );

            this.$patch( ( state ) =>
                mutateResults( state, 'projectEntries', queryUuid, projectUuid, results ),
            );
        },

        // endregion

        // region Entries
        patchProjectEntry( projectUuid: string, entry: ApiProjectEntry ) {
            patchProjectQueryResult( this, projectUuid, entry );

            patchProjectEntryQueryResult( this, projectUuid, entry );
        },

        // endregion

        // region Pending query
        setPendingQuery( query: SearchQuery ) {
            Vue.set( this.pendingQuery, query.uuid(), query.mutableProperties() );
        },

        resetPendingQuery() {
            this.pendingQuery = {};
        },

        setPendingQueryCondition(
            queryUuid: string,
            conditionType: SearchConditionType,
            keywords: SearchConditionKeyword[],
        ) {
            if ( !this.pendingQuery[queryUuid] ) {
                throw new Error( `Unexpected state: pending query is not
                available for base query with UUID ${queryUuid}.` );
            }

            let newConditions = [...( this.pendingQuery[queryUuid].conditions || [] )];

            switch ( conditionType ) {
                case 'keyword-filter':
                    newConditions = this.addRequiredCondition( keywords, newConditions );
                    break;

                case 'product':
                    newConditions = this.addProductCondition( queryUuid, keywords, newConditions );
                    break;

                case 'context':
                    newConditions = this.addContextCondition( queryUuid, keywords, newConditions );
                    break;
            }

            // Remove conditions without any keywords to avoid errors.
            newConditions = newConditions.filter( ( c ) => c.keywords.length > 0 );

            Vue.set( this.pendingQuery[queryUuid], 'conditions', newConditions );
        },

        /**
         * If a required condition is passed, just replace it, as it does not
         * affect product or context conditions.
         */
        addRequiredCondition( keywords: SearchConditionKeyword[], newConditions: SearchCondition[] ) {
            newConditions = newConditions.filter( ( { type } ) => type !== 'keyword-filter' );

            newConditions.push( {
                keywords: keywords.slice( 0, maxKeywordsPerCondition ),
                required: true,
                type: 'keyword-filter',
            } );

            return newConditions;
        },

        addProductCondition(
            queryUuid: string,
            keywords: SearchConditionKeyword[],
            newConditions: SearchCondition[],
        ) {
            newConditions = newConditions.filter( ( { type } ) => type !== 'product' );
            const productKeywords = keywords;
            const contextKeywords = this.getPendingQueryConditionKeywords( queryUuid, 'context' );

            // Always set the first product keyword required.
            if ( productKeywords.length > 0 ) {
                newConditions = this.addRequiredCondition( [productKeywords[0]], newConditions );
            }

            newConditions.push( {
                keywords: productKeywords.slice( 0, maxKeywordsPerCondition ),
                required: 1,
                type: 'product',
            } );

            newConditions = this.deduplicateContextCondition(
                productKeywords,
                contextKeywords,
                newConditions,
            );

            return newConditions;
        },

        addContextCondition(
            queryUuid: string,
            keywords: SearchConditionKeyword[],
            newConditions: SearchCondition[],
        ) {
            newConditions = newConditions.filter( ( { type } ) => type !== 'context' );
            const contextKeywords = keywords;
            const productKeywords = this.getPendingQueryConditionKeywords( queryUuid, 'product' );

            newConditions.push( {
                keywords: contextKeywords.slice( 0, maxKeywordsPerCondition ),
                required: 0,
                type: 'context',
            } );

            newConditions = this.deduplicateContextCondition(
                productKeywords,
                contextKeywords,
                newConditions,
            );

            return newConditions;
        },

        deduplicateContextCondition(
            productKeywords: SearchConditionKeyword[],
            contextKeywords: SearchConditionKeyword[],
            newConditions: SearchCondition[],
        ) {
            const lowercasedProductKeywords =
                productKeywords.map( ( k ) => k.term.toLowerCase() ) || [];
            const filteredContextKeywords = contextKeywords.filter(
                ( k ) => !lowercasedProductKeywords.includes( k.term.toLowerCase() ),
            );
            const hasFilteredOutContextKeywords =
                contextKeywords.length !== filteredContextKeywords.length;

            if ( hasFilteredOutContextKeywords ) {
                newConditions = newConditions.filter( ( { type } ) => type !== 'context' );

                newConditions.push( {
                    keywords: filteredContextKeywords.slice( 0, maxKeywordsPerCondition ),
                    required: 0,
                    type: 'context',
                } );
            }

            return newConditions;
        },

        setPendingQueryFilter( queryUuid: string, { filter }: AddFilterEvent ) {
            if ( !this.pendingQuery[queryUuid] ) {
                throw new Error( `Unexpected state: pending query is not
                available for base query with UUID ${queryUuid}.` );
            }

            // Prevent multiple geographical filters from interfering with each other.
            const filters =
                ( ['geoRegion', 'geoBoundingBox'].includes( filter.field )
                    ? this.pendingQuery[queryUuid].filters?.filter(
                          ( { field } ) => !['geoRegion', 'geoBoundingBox'].includes( field ),
                      )
                    : this.pendingQuery[queryUuid].filters?.filter(
                          ( { field } ) => field !== filter.field,
                      ) ) ?? [];

            Vue.set( this.pendingQuery[queryUuid], 'filters', [...filters, filter] );
        },

        removePendingQueryFilter( queryUuid: string, { filter, group }: RemoveFilterEvent ) {
            if ( !this.pendingQuery[queryUuid] ) {
                throw new Error( `Unexpected state: pending query is not
                available for base query with UUID ${queryUuid}.` );
            }

            // If the removal of a geoRegion filter is requested, check by the
            // passed group parameter, which part of it is to be removed.
            const filters =
                ( filter.field === 'geoRegion'
                    ? this.pendingQuery[queryUuid].filters
                          ?.map( ( activeFilter ) => {
                              if ( activeFilter.field === 'geoRegion' && group ) {
                                  delete ( activeFilter as SearchFilter<'geoRegion'> ).value[group];
                              }

                              return activeFilter;
                          } )
                          .filter(
                              ( activeFilter ) =>
                                  activeFilter.field !== 'geoRegion' ||
                                  Object.keys( ( activeFilter as SearchFilter<'geoRegion'> ).value )
                                      .length > 0,
                          )
                    : this.pendingQuery[queryUuid].filters?.filter(
                          ( activeFilter ) => activeFilter.field !== filter.field,
                      ) ) ?? [];

            Vue.set( this.pendingQuery[queryUuid], 'filters', filters );
        },

        // endregion
    },

    getters: {
        // region Queries
        activeGeneralQuery( { activeGeneralQueryUuid, generalQueries } ) {
            if ( !activeGeneralQueryUuid ) {
                return null;
            }

            const query = generalQueries.find( ( { uuid } ) => uuid === activeGeneralQueryUuid );

            return query ? new SearchQuery( query ) : null;
        },

        getActiveProjectEntryQuery( { activeProjectEntryQueryUuids, projectEntryQueries } ) {
            return ( projectUuid: string ) => {
                const queries = Object.fromEntries(
                    Object.entries( activeProjectEntryQueryUuids )
                        .map(
                            ( [key, value] ) =>
                                [
                                    key,
                                    projectEntryQueries[key].find( ( { uuid } ) => uuid === value ),
                                ] as const,
                        )
                        .filter( ( entry ): entry is [string, ApiSearchQuery] => !!entry[1] ),
                );

                return projectUuid in queries ? new SearchQuery( queries[projectUuid] ) : null;
            };
        },

        getActiveProjectQuery( { activeProjectQueryUuids, projectQueries } ) {
            return ( projectUuid: string ) => {
                const queries = Object.fromEntries(
                    Object.entries( activeProjectQueryUuids )
                        .map(
                            ( [key, value] ) =>
                                [
                                    key,
                                    projectQueries[key].find( ( { uuid } ) => uuid === value ),
                                ] as const,
                        )
                        .filter( ( entry ): entry is [string, ApiSearchQuery] => !!entry[1] ),
                );

                return projectUuid in queries ? new SearchQuery( queries[projectUuid] ) : null;
            };
        },

        getProjectQueries( { historicalProjectQueries } ) {
            return ( projectUuid: string ) =>
                ( historicalProjectQueries[projectUuid] ?? [] ).map( ( q ) => new SearchQuery( q ) );
        },

        getProjectQuery() {
            return ( projectUuid: string, queryUuid: string ) =>
                this.getProjectQueries( projectUuid ).find( ( query ) => query.uuid() === queryUuid );
        },

        getAggregations( { resultsAggregations } ) {
            return ( queryUuid: string ) => resultsAggregations[queryUuid] ?? [];
        },

        getAugmentations( { resultsAugmentations } ) {
            return ( queryUuid: string ) => resultsAugmentations[queryUuid] ?? {};
        },

        getAvailableResultCompanies( { resultCompaniesAvailable } ) {
            return ( queryUuid: string ) => resultCompaniesAvailable[queryUuid] ?? 0;
        },

        // endregion

        // region Pending query
        /**
         * Get the pending query's resource payload, when the user triggers a
         * new search, and a new query is to be created.
         */
        getPendingQueryPayload( { pendingQuery } ) {
            return ( queryUuid: string ) => pendingQuery[queryUuid] ?? null;
        },

        /**
         * Get the pending query's domain object, when access is needed to the
         * domain object's methods.
         */
        getPendingQuery( { pendingQuery } ) {
            return ( queryUuid: string ) => {
                const query = pendingQuery[queryUuid];

                if ( !query ) {
                    return null;
                }

                return new SearchQuery( {
                    createdAt: new Date().toISOString(),
                    deletedAt: '',
                    updatedAt: new Date().toISOString(),
                    uuid: 'candidate',

                    aggregations: query.aggregations ?? [],
                    conditions: query.conditions ?? [],
                    filters: query.filters ?? [],
                    previousQueryUuid: query.previousQueryUuid ?? null,
                } );
            };
        },

        getPendingQueryConditionKeywords( { pendingQuery } ) {
            return ( queryUuid: string, condition: SearchConditionType ) => {
                if ( !pendingQuery[queryUuid] ) {
                    return [];
                }

                return (
                    pendingQuery[queryUuid].conditions?.find( ( { type } ) => type === condition )
                        ?.keywords ?? []
                );
            };
        },

        // endregion

        // region Query results
        getGeneralQueryResults( { generalQueryResults } ) {
            return ( queryUuid: string ) =>
                Object.values( generalQueryResults[queryUuid] ?? {} )
                    .flat()
                    .map( ( result ) => new SearchResult( result ) );
        },

        getProjectEntryQueryResults( { projectEntryQueryResults } ) {
            return ( queryUuid: string ) =>
                Object.values( projectEntryQueryResults[queryUuid] ?? {} ).flat();
        },

        getProjectQueryResults( { projectQueryResults } ) {
            return ( queryUuid: string ) =>
                Object.values( projectQueryResults[queryUuid] ?? {} )
                    .flat()
                    .map( ( result ) => new SearchResult( result ) );
        },

        getProjectQueryResultsPerPage( { projectQueryResults } ) {
            return ( queryUuid: string, page: number ) =>
                ( projectQueryResults[queryUuid]?.[page] ?? [] ).map(
                    ( result ) => new SearchResult( result ),
                );
        },

        getAvailableResults( { resultsAvailable } ) {
            return ( queryUuid: string ) => resultsAvailable[queryUuid] ?? 0;
        },

        // endregion

        // region Pages
        getPagination( { resultsPaginations } ) {
            return ( queryUuid: string ) =>
                resultsPaginations[queryUuid] ?? {
                    currentFirstPage: undefined,
                    currentLastPage: undefined,
                    lastPage: undefined,
                    page: undefined,
                    perPage: undefined,
                };
        },

        getGeneralQueryPage( { generalQueryResults } ) {
            return ( queryUuid: string, page: number ) =>
                generalQueryResults[queryUuid]
                    ? ( generalQueryResults[queryUuid][page] ?? null )
                    : null;
        },

        getProjectQueryPage( { projectQueryResults } ) {
            return ( queryUuid: string, page: number ) =>
                projectQueryResults[queryUuid]?.[page]
                    ? projectQueryResults[queryUuid][page]
                    : null;
        },

        getProjectEntryQueryPage( { projectEntryQueryResults } ) {
            return ( queryUuid: string, page: number ) =>
                projectEntryQueryResults[queryUuid]?.[page]
                    ? projectEntryQueryResults[queryUuid][page]
                    : null;
        },

        getTotalMatches( { resultsTotals } ) {
            return ( queryUuid: string ) => resultsTotals[queryUuid] ?? 0;
        },

        isDefaultProjectQuery( { defaultProjectQueries } ) {
            return ( projectUuid: string, queryUuid: string ) =>
                defaultProjectQueries[projectUuid]?.uuid === queryUuid;
        },

        // endregion
    },
} );

function mutateQuery(
    state: State,
    queryType: QueryType,
    makeActiveQuery: boolean,
    query: ApiSearchQuery,
    projectUuid?: string,
) {
    switch ( queryType ) {
        case 'general':
            state.generalQueries = patchList( state.generalQueries, query, 'uuid' );

            break;

        case 'project':
            if ( !projectUuid ) {
                throw new Error( 'Expected project UUID.' );
            }

            Vue.set(
                state.projectQueries,
                projectUuid,
                patchList( state.projectQueries[projectUuid], query, 'uuid' ),
            );

            break;

        case 'projectEntries':
            if ( !projectUuid ) {
                throw new Error( 'Expected project UUID.' );
            }

            Vue.set(
                state.projectEntryQueries,
                projectUuid,
                patchList( state.projectEntryQueries[projectUuid], query, 'uuid' ),
            );

            break;
    }

    if ( makeActiveQuery ) {
        mutateLastQuery( state, queryType, query.uuid, projectUuid );
    }
}

function mutateLastQuery(
    state: State,
    queryType: QueryType,
    queryUuid: string | null,
    projectUuid?: string,
) {
    switch ( queryType ) {
        case 'general':
            state.activeGeneralQueryUuid = queryUuid;
            break;

        case 'project':
            if ( !projectUuid ) {
                throw new Error( 'Expected project UUID.' );
            }

            Vue.set( state.activeProjectQueryUuids, projectUuid, queryUuid );

            break;

        case 'projectEntries':
            if ( !projectUuid ) {
                throw new Error( 'Expected project UUID.' );
            }

            Vue.set( state.activeProjectEntryQueryUuids, projectUuid, queryUuid );

            break;
    }
}

function mutateResults(
    state: State,
    queryType: QueryType,
    queryUuid: string,
    projectUuid: string | null,
    resultsReturn: FetchApiSearchResultsReturn | FetchApiProjectEntryResultsReturn,
) {
    const { results, total, currentPage, perPage, lastPage, aggregations } = resultsReturn;

    // In case we get a FetchApiSearchResultsReturn, we need to extract
    // more details about the returned result counts.
    const hitCount = ( resultsReturn as FetchApiSearchResultsReturn ).hitCount ?? total;
    const availableResults =
        ( resultsReturn as FetchApiSearchResultsReturn ).availableResultsCount ?? total;
    const availableCompanies =
        ( resultsReturn as FetchApiSearchResultsReturn ).availableCompaniesCount ?? total;
    const augmentations = ( resultsReturn as FetchApiSearchResultsReturn ).augmentations ?? {};

    // Sanity check
    if ( availableResults !== total ) {
        throw new Error( 'Unexpected state: availableResults and total do not match.' );
    }

    // Determine where to store the results
    let resultsState;

    switch ( queryType ) {
        case 'general':
            resultsState = state.generalQueryResults;
            break;
        case 'project':
            resultsState = state.projectQueryResults;
            break;
        case 'projectEntries':
            resultsState = state.projectEntryQueryResults;
            break;
    }

    const previousResultsPerPage = resultsState[queryUuid] ?? {};

    /**
     * The ES ranking can slightly differ from query to query. Thus, the
     * following can happen:
     *
     * - The user executes a query and paginates to page 3 of the result list.
     * - Company A is ranked on position 90 for this request, so it ends up in
     * the results for page 3, given the perPage value of 30.
     * - Now the user paginates to page 4.
     * - The backend, like with each ES request, re-evaluates all ES results.
     * - Company A is now ranked on position 91 in this request.
     * - The frontend adds the new 30 results to the previous results.
     * - We now have a duplicate in the search results.
     *
     * To avoid this, we check for seen UUIDs in the previous results and
     * filter the 30 new results, if needed.
     *
     * This will probably not happen very often. However, it is to be seen as a
     * workaround. We might want to figure out, why the ranking differs, as ES
     * states that with a sufficient amount of data, ranking will practically
     * not differ.
     *
     * @see https://www.elastic.co/guide/en/elasticsearch/guide/current/relevance-is-broken.html
     */
    const newResults = determineNewResultsForCurrentPage(
        previousResultsPerPage,
        results,
        currentPage,
    );

    // Create a new pages record, patch the new page in.
    const pages = {
        ...previousResultsPerPage,
        [currentPage]: newResults,
    };

    const [lowerPage, upperPage] = resolveBrackets( pages, currentPage );

    // Now that we determined the current frame brackets, we check
    // whether the current frame delta exceeds them and remove the upper
    // or lower page that falls out of the frame as necessary.
    if ( upperPage - lowerPage > 2 ) {
        if ( currentPage <= lowerPage ) {
            delete pages[upperPage];
        } else {
            delete pages[lowerPage];
        }
    }

    Vue.set( resultsState, queryUuid, pages );
    Vue.set( state.resultsTotals, queryUuid, hitCount );
    Vue.set( state.resultsAvailable, queryUuid, availableResults );
    Vue.set( state.resultCompaniesAvailable, queryUuid, availableCompanies );

    // Above, we limited the page frame. To update the pagination info
    // in the store, we'll need to resolve the brackets of the current
    // frame, so we get accurate data on the current set of pages.
    const [first, last] = resolveBrackets( pages, currentPage );

    Vue.set( state.resultsPaginations, queryUuid, {
        currentFirstPage: first,
        currentLastPage: last,
        lastPage,
        page: currentPage,
        perPage,
    } as PaginationInfo );

    Vue.set( state.resultsAggregations, queryUuid, aggregations );

    Vue.set( state.resultsAugmentations, queryUuid, augmentations );
}

function limitStoredQueries( state: State, queryType: QueryType, projectUuid?: string ) {
    // Check whether limit is reached.

    switch ( queryType ) {
        case 'general':
            if ( state.generalQueries.length < MAX_STORED_QUERIES ) {
                return;
            }

            break;

        case 'project':
            if ( !projectUuid ) {
                throw new Error( 'Expected project UUID.' );
            }

            if ( ( state.projectQueries[projectUuid]?.length || 0 ) < MAX_STORED_QUERIES ) {
                return;
            }

            break;

        case 'projectEntries':
            if ( !projectUuid ) {
                throw new Error( 'Expected project UUID.' );
            }

            if ( ( state.projectEntryQueries[projectUuid]?.length || 0 ) < MAX_STORED_QUERIES ) {
                return;
            }

            break;
    }

    // Remove the oldest query and its results.

    let oldestQueryUuid;

    switch ( queryType ) {
        case 'general':
            oldestQueryUuid = state.generalQueries[0].uuid;
            state.generalQueries.shift();
            Vue.delete( state.generalQueryResults, oldestQueryUuid );

            break;

        case 'project':
            if ( !projectUuid ) {
                throw new Error( 'Expected project UUID.' );
            }

            oldestQueryUuid = state.projectQueries[projectUuid][0].uuid;
            Vue.delete( state.projectQueries, oldestQueryUuid );
            Vue.delete( state.projectQueryResults, oldestQueryUuid );

            break;

        case 'projectEntries':
            if ( !projectUuid ) {
                throw new Error( 'Expected project UUID.' );
            }

            oldestQueryUuid = state.projectEntryQueries[projectUuid][0].uuid;
            Vue.delete( state.projectEntryQueries, oldestQueryUuid );
            Vue.delete( state.projectEntryQueryResults, oldestQueryUuid );

            break;
    }

    Vue.delete( state.resultsTotals, oldestQueryUuid );
    Vue.delete( state.resultsAvailable, oldestQueryUuid );
    Vue.delete( state.resultsPaginations, oldestQueryUuid );
    Vue.delete( state.resultsAggregations, oldestQueryUuid );
    Vue.delete( state.resultsAugmentations, oldestQueryUuid );
}

/**
 * We allow for mutating the results with updated project entries, in
 * order not to trigger a server round trip each time a user modifies
 * a project entry.
 */
function patchProjectQueryResult( state: State, projectUuid: string, entry: ApiProjectEntry ) {
    // There aren't any queries for the project whose entries got updated.
    if ( !state.projectQueries[projectUuid] ) {
        return;
    }

    // Get all query uuids related to the given project.
    const queries = state.projectQueries[projectUuid].map( ( { uuid } ) => uuid );

    // Now update each query's results.
    queries.forEach( ( uuid ) => {
        if ( !( uuid in state.projectQueryResults ) ) {
            return;
        }

        // The results per query return an object that maps page numbers to
        // actual SearchResults.
        // Update given entry in each page.
        const updatedPages = Object.entries( state.projectQueryResults[uuid] ).reduce<
            Record<number, ApiSearchResult[]>
        >( ( carry, [pageNumber, results] ) => {
            carry[Number( pageNumber )] = results.map( ( result ) => {
                // Check whether the top match or any of the additional matches
                // is the company in question.
                const containsCompany =
                    result.topMatch.uuid === entry.uuid ||
                    result.additionalMatches.find( ( c ) => c.uuid === entry.uuid );

                if ( !containsCompany ) {
                    return result;
                }

                // Add or replace the entry for the company in question.
                const entries = result.projectEntries ?? [];
                const projectEntries = entries.filter( ( { uuid } ) => uuid !== entry.uuid );
                projectEntries.push( entry );

                return { ...result, projectEntries };
            } );

            return carry;
        }, {} );

        Vue.set( state.projectQueryResults, uuid, updatedPages );
    } );
}

/**
 * We allow for mutating the results with updated project entries, in
 * order not to trigger a server round trip each time a user modifies
 * a project entry.
 */
function patchProjectEntryQueryResult( state: State, projectUuid: string, entry: ApiProjectEntry ) {
    // There aren't any queries for the project whose entries got updated.
    if ( !( projectUuid in state.projectEntryQueries ) ) {
        return;
    }

    // Get all query uuids related to the given project.
    state.projectEntryQueries[projectUuid]
        .map( ( { uuid } ) => uuid )

        // Now update each query's results.
        .forEach( ( uuid ) => {
            // The results per query return an object that maps page numbers to
            // actual SearchResults.
            // Update given entry in each page.
            const updatedPages = Object.entries( state.projectEntryQueryResults[uuid] ).reduce<
                Record<number, ApiProjectEntry[]>
            >( ( carry, [pageNumber, entries] ) => {
                carry[Number( pageNumber )] = entries.map( ( mappedEntry ) =>
                    mappedEntry.uuid === entry.uuid
                        ? Object.assign( mappedEntry, entry )
                        : mappedEntry,
                );

                return carry;
            }, {} );

            Vue.set( state.projectEntryQueryResults, uuid, updatedPages );
        } );
}

function enrichQueryWithDefaultAggregations( query: NewApiSearchQuery ) {
    return {
        ...query,
        aggregations: ApiSearchQueryDefaultAggregations,
    };
}

function enrichQueryWithDebugAggregations( query: NewApiSearchQuery ) {
    if ( query.aggregations?.some( ( a ) => ApiSearchQueryDebugAggregations.includes( a ) ) ) {
        return query;
    }

    return {
        ...query,
        aggregations: query.aggregations?.concat( ApiSearchQueryDebugAggregations ),
    };
}

/**
 * Frame limiting
 * ==============
 * Keep a maximum of 3 pages in the store to prevent too many DOM nodes.
 *
 * To prevent performance issues due to excessive DOM node usage, we limit the
 * amount of search results in memory to a given number of pages. Depending on
 * the direction of the current fetch (a lower page or a higher page?), we'll
 * want to check whether the current number of pages in the store exceeds our
 * window, and if so, remove the first or last page that "falls out" of
 * that window.
 * Essentially, what we want is this:
 * -- ... --2--3--[ 4, 5, 6 ]--7--8-- ... --
 * From all pages on the server, we're storing page 4, 5 and 6 locally. If the
 * user chooses to load the previous page, we'll fetch page 3 and discard 6.
 * -- ... --2--[ 3, 4, 5 ]--6--7--8-- ... --
 * If the user requests the next page, we'll do the contrary, so fetch page 7
 * and discard 4.
 * -- ... --2--3--4--[ 5, 6, 7 ]--8-- ... --
 * The following function quite literally resolves the position of the brackets
 * in the example above!
 *
 * @param items
 * @param current
 */
function resolveBrackets<T>( items: Record<number, T>, current: number ): [number, number] {
    const range = Object.keys( items );

    // Make sure numbers are sorted before determining bracket values
    // (Javascript's array sort converts to string by default)
    range.sort( ( a, b ) => Number( a ) - Number( b ) );

    const lower = Number( range[0] || current );
    const upper = Number( range[range.length - 1] || current );

    return [lower, upper];
}

if ( import.meta.hot ) {
    import.meta.hot.accept( acceptHMRUpdate( useSearchStore, import.meta.hot ) );
}

type QueryType = 'general' | 'project' | 'projectEntries';

export type PaginationInfo = {
    currentFirstPage: number;
    currentLastPage: number;
    perPage: number;
    lastPage: number;
    page: number;
};

/**
 * If there are no results for the current page yet, then that means
 * we just received a batch of search results for a new page. We want
 * to compare it against existing results to find potential duplicates.
 *
 * If results already exist for the current page, then a page is
 * requested for which results have been fetched before, so we
 * return the results as they are.
 */
function determineNewResultsForCurrentPage(
    previousResultsPerPage: Record<number, ( ApiSearchResult | ApiProjectEntry )[]>,
    results: ( ApiSearchResult | ApiProjectEntry )[],
    currentPage: number,
) {
    const hasResultsForCurrentPage = currentPage in previousResultsPerPage;

    if ( hasResultsForCurrentPage ) {
        return results;
    }

    const previousUuids = Object.values( previousResultsPerPage ).flatMap( ( results ) =>
        results.map( ( { uuid } ) => uuid ),
    );

    return results.filter( ( r ) => !previousUuids.includes( r.uuid ) );
}
