// noinspection JSUnusedLocalSymbols

import type { ListOptions } from '@main/api/plugin/types/api';
import type { ApiCompanySummary } from '@main/api/resources/companies';
import {
    addProjectEntryCommentAction,
    addProjectEntryExcludeAction,
    addProjectEntrySelectAction,
    addProjectEntryVisitAction,
    type ApiProjectEntry,
    type ApiProjectEntryAction,
    type ApiProjectEntryFilters,
    type ApiProjectEntryIncludes,
    type ApiProjectPhase,
    type ApiProjectWithPhases,
    type DiscoveryState,
    fetchExcludedProjectEntries,
    fetchProjectEntries,
    fetchProjectEntry,
    fetchProjectEntryActions,
    fetchProjectPhaseEntries,
    fetchProjectReferenceEntries,
} from '@main/api/resources/projects';
import { ProjectEntry } from '@main/domain/projects/ProjectEntry';
import { useAuthStore } from '@main/store/stores/auth';
import { useProjectsStore } from '@main/store/stores/projects';
import { useSearchStore } from '@main/store/stores/search';
import { patchList } from '@main/utilities/store';
import { acceptHMRUpdate, defineStore } from 'pinia';
import Vue from 'vue';

type State = {
    /**
     * Actions per project and per entry UUID
     */
    actions: Record<string, Record<string, ApiProjectEntryAction[]>>;

    /**
     * Entries of any phase per project UUID
     */
    entries: Record<string, ApiProjectEntry[]>;
    referenceEntries: Record<string, ApiProjectEntry[]>;

    /**
     * Whether the edit references modal should be open.
     */
    updateReferencesActive: boolean;
};

export const useProjectEntriesStore = defineStore( 'projectEntries', {
    persist: false,

    state(): State {
        return {
            actions: {},
            entries: {},
            referenceEntries: {},
            updateReferencesActive: false,
        };
    },

    actions: {
        async select(
            projectUuid: string,
            phaseUuid: string,
            company: ApiCompanySummary,
            keywords?: string[],
            searchQueryUuid?: string,
            comment?: string | undefined,
            discoveryState?: DiscoveryState | undefined,
        ) {
            const entry = await addProjectEntrySelectAction( projectUuid, phaseUuid, company.uuid, {
                comment,
                discoveryState,
                keywords,
                searchQuery: searchQueryUuid,
            } );
            const newEntry = { ...entry, company };

            patchProjectEntries( this, projectUuid, [newEntry] );
            updateActions( this, projectUuid, newEntry.uuid, newEntry.triggerAction! );

            return newEntry;
        },

        async visit( projectUuid: string, company: ApiCompanySummary ) {
            const entry = await addProjectEntryVisitAction( projectUuid, company.uuid );
            const newEntry = { ...entry, company };

            patchProjectEntries( this, projectUuid, [newEntry] );

            // As opposed to all other actions, we are not interested in visit
            // actions, so we do not update the stored actions.
            return newEntry;
        },

        async comment( projectUuid: string, company: ApiCompanySummary, comment: string ) {
            const entry = await addProjectEntryCommentAction( projectUuid, company.uuid, {
                comment,
            } );
            const newEntry = { ...entry, company };

            patchProjectEntries( this, projectUuid, [newEntry] );
            updateActions(
                this,
                projectUuid,
                newEntry.uuid,
                newEntry.triggerAction as ApiProjectEntryAction,
            );

            return newEntry;
        },

        async exclude(
            projectUuid: string,
            company: ApiCompanySummary,
            searchQueryUuid?: string,
            comment?: string,
            reported?: boolean,
        ) {
            const entry = await addProjectEntryExcludeAction( projectUuid, company.uuid, {
                comment,
                excluded: true,
                reported,
                searchQuery: searchQueryUuid,
            } );
            const newEntry = { ...entry, company };

            patchProjectEntries( this, projectUuid, [newEntry] );

            updateActions(
                this,
                projectUuid,
                newEntry.uuid,
                newEntry.triggerAction as ApiProjectEntryAction,
            );

            return newEntry;
        },

        async unExclude( projectUuid: string, company: ApiCompanySummary ) {
            const entry = await addProjectEntryExcludeAction( projectUuid, company.uuid, {
                excluded: false,
                reported: false,
            } );

            const newEntry = { ...entry, company };

            patchProjectEntries( this, projectUuid, [newEntry] );

            updateActions(
                this,
                projectUuid,
                newEntry.uuid,
                newEntry.triggerAction as ApiProjectEntryAction,
            );

            return newEntry;
        },

        /**
         * TODO: check if we still need this method.
         *
         * @param projectUuid
         * @param options
         */
        async fetchProjectEntries( projectUuid: string, options?: ListOptions<ApiProjectEntry> ) {
            const { entries } = await fetchProjectEntries( projectUuid, options );

            mutateProjectEntries( this, projectUuid, entries );
        },

        async fetchProjectPhaseEntries(
            projectUuid: string,
            phaseUuid: string,
            options?: ListOptions<ApiProjectEntry, ApiProjectEntryIncludes, ApiProjectEntryFilters>,
        ) {
            const { entries } = await fetchProjectPhaseEntries( projectUuid, phaseUuid, options );

            mutateProjectEntries( this, projectUuid, entries );
        },

        async fetchProjectEntry( projectUuid: string, entryUuid: string ) {
            const entry = await fetchProjectEntry( projectUuid, entryUuid );

            if ( !entry ) {
                return;
            }

            this.$patch( ( state ) => {
                const all = this.entries[projectUuid] || [];
                all.push( entry );
                Vue.set( state.entries, projectUuid, all );
            } );
        },

        async fetchExcludedProjectEntries(
            projectUuid: string,
            options?: ListOptions<ApiProjectEntry, ApiProjectEntryIncludes, ApiProjectEntryFilters>,
        ) {
            const { entries } = await fetchExcludedProjectEntries( projectUuid, options );

            mutateProjectEntries( this, projectUuid, entries );
        },

        async refreshProjectEntry( projectUuid: string, entryUuid: string ) {
            const entry = await fetchProjectEntry( projectUuid, entryUuid );

            if ( !entry ) {
                return;
            }

            patchProjectEntry( this, projectUuid, entry );

            // Tell other stores that hold entries about the refreshed data
            useSearchStore().patchProjectEntry( projectUuid, entry );
        },

        async fetchProjectEntryActions(
            projectUuid: string,
            projectEntryUuid: string,
            options?: ListOptions<ApiProjectEntryAction>,
        ) {
            const { actions } = await fetchProjectEntryActions(
                projectUuid,
                projectEntryUuid,
                options,
            );

            // Create a new object for the actions of the project in question,
            // so that we can assign it to this.actions[ projectUuid ] below,
            // and it triggers reactivity.
            const projectActions = Object.assign( {}, this.actions[projectUuid] || {} );

            projectActions[projectEntryUuid] = actions;

            Vue.set( this.actions, projectUuid, projectActions );
        },

        async fetchProjectReferenceEntries( projectUuid: string ) {
            const { entries } = await fetchProjectReferenceEntries( projectUuid );

            if ( !entries ) {
                return;
            }

            Vue.set( this.referenceEntries, projectUuid, entries );
        },

        setUpdateReferencesActive( value: boolean ) {
            this.updateReferencesActive = value;
        },
    },

    getters: {
        getProjectPhaseEntries( { entries } ) {
            // We also check for excluded as entries can be excluded after they
            // were loaded.
            return ( projectUuid: string, phaseUuid: string ) =>
                entries[projectUuid]?.filter(
                    ( { excluded, phaseUuid: uuid } ) => uuid === phaseUuid && !excluded,
                ) ?? [];
        },

        getProjectEntries( { entries } ) {
            return ( projectUuid: string ) => entries[projectUuid] ?? [];
        },

        getProjectReferenceEntries( { referenceEntries } ) {
            return ( projectUuid: string ) => referenceEntries[projectUuid] ?? [];
        },

        getExcludedProjectEntries( { entries } ) {
            return ( projectUuid: string ) => {
                const excludedEntries =
                    entries[projectUuid]?.filter( ( { excluded } ) => excluded ) ?? [];

                return excludedEntries.map( ( entry ) => new ProjectEntry( entry ) );
            };
        },

        getProjectEntry( { entries } ) {
            // We also check for excluded as entries can be excluded after they
            // were loaded.
            return ( projectUuid: string, entryUuid: string ) =>
                entries[projectUuid]?.find(
                    ( { excluded, uuid } ) => uuid === entryUuid && !excluded,
                ) ?? null;
        },

        getProjectEntryActions( { actions } ) {
            return ( projectUuid: string, entryUuid: string ) => {
                if ( !actions[projectUuid] ) {
                    return [];
                }

                return actions[projectUuid][entryUuid] ?? [];
            };
        },
    },
} );

// region Helpers

function patchProjectEntries( state: State, projectUuid: string, entries: ApiProjectEntry[] ) {
    const searchStore = useSearchStore();

    entries.forEach( ( entry ) => {
        // Update item in this store.
        patchProjectEntry( state, projectUuid, entry );

        // Tell other stores that hold entries about the update.
        searchStore.patchProjectEntry( projectUuid, entry );
    } );
}

function mutateProjectEntries( state: State, projectUuid: string, entries: ApiProjectEntry[] ) {
    // We merge the new entries into the array of existing ones.
    // As the new ones got fetched from the server, we consider them
    // to be the source of truth and ignore the ones we have already stored.
    // This can happen when we exclude entries or move them to a different phase.
    // If we fetch all excluded entries or the entries of the target phase
    // later on, we'll get duplicates of these entries.
    const newOrUpdatedIds = entries.map( ( e ) => e.uuid );
    const current = state.entries[projectUuid] || [];
    const untouched = current.filter( ( { uuid } ) => !newOrUpdatedIds.includes( uuid ) );
    const all = untouched.concat( entries );

    Vue.set( state.entries, projectUuid, all );
}

function patchProjectEntry( state: State, projectUuid: string, entry: ApiProjectEntry ) {
    // Find the project.
    const project = useProjectsStore().getProject( projectUuid );

    // Project has not been loaded yet.
    if ( !project ) {
        // Can happen when we e.g. select an entry in another project
        // than the current one.
        return;
    }

    // Find all entries for given project UUID.
    const entries = projectUuid in state.entries ? state.entries[projectUuid] : null;

    if ( !entries ) {
        // Can happen when we e.g. select an entry in another project
        // than the current one.
        return;
    }

    // Find the given entry.
    const currentEntry = entries.find( ( { uuid } ) => uuid === entry.uuid );

    if ( currentEntry ) {
        patchExistingProjectEntry( state, project.apiResource(), currentEntry, entry );
    } else {
        // The given entry is new.
        patchNewProjectEntry( state, project.apiResource(), entry );
    }
}

function patchNewProjectEntry(
    state: State,
    project: ApiProjectWithPhases,
    newEntry: ApiProjectEntry,
) {
    // Add the new entry.
    state.entries[project.uuid].push( newEntry );

    // Now, update the phase counts in the project.
    if ( newEntry.excluded ) {
        // The new entry got immediately excluded,
        // so it won't show up in any phase.
        return;
    }

    if ( !newEntry.phaseUuid ) {
        // The new entry got added, but not selected
        // in a phase.
        return;
    }

    let updatedProject: ApiProjectWithPhases = { ...project };

    const phase = updatedProject.phases?.find( ( { uuid } ) => uuid === newEntry.phaseUuid );

    if ( newEntry.phaseUuid && !phase ) {
        throw new Error( 'Make sure to include phases when loading projects' );
    }

    if ( phase ) {
        // This is a new entry, that was not assigned to a phase.
        // E.g. it was simply visited.
        updatedProject = patchProjectPhaseCount( updatedProject, phase, phase.count + 1 );
    }

    const projectsStore = useProjectsStore();
    projectsStore.apiProjects = patchList( projectsStore.apiProjects, updatedProject, 'uuid' );
}

function patchExistingProjectEntry(
    state: State,
    project: ApiProjectWithPhases,
    currentEntry: ApiProjectEntry,
    updatedEntry: ApiProjectEntry,
) {
    const currentPhase = currentEntry.phaseUuid;
    const updatedPhase = updatedEntry.phaseUuid;

    // Patch the current entry, by replacing its properties,
    // so that reactivity triggers.
    Object.assign( currentEntry, updatedEntry );

    patchProject( state, project, currentPhase, updatedPhase );
}

function patchProject(
    state: State,
    project: ApiProjectWithPhases,
    currentPhase: string | null,
    updatedPhase: string | null,
) {
    // Clone project.
    let updatedProject: ApiProjectWithPhases = Object.assign( {}, project );

    // Update phase counts if phase has changed.
    if ( currentPhase !== updatedPhase ) {
        if ( currentPhase ) {
            const phase = updatedProject.phases?.find( ( { uuid } ) => uuid === currentPhase );

            if ( !phase ) {
                throw new Error(
                    'Patching current phase: Make sure to include phases when loading projects.',
                );
            }

            updatedProject = patchProjectPhaseCount( updatedProject, phase, phase.count - 1 );
        }

        if ( updatedPhase ) {
            const phase = updatedProject.phases?.find( ( { uuid } ) => uuid === updatedPhase );

            if ( !phase ) {
                throw new Error(
                    'Patching updated phase: Make sure to include phases when loading projects',
                );
            }

            updatedProject = patchProjectPhaseCount( updatedProject, phase, phase.count + 1 );
        }
    }

    const projectsStore = useProjectsStore();
    projectsStore.apiProjects = patchList( projectsStore.apiProjects, updatedProject, 'uuid' );
}

function patchProjectPhaseCount(
    project: ApiProjectWithPhases,
    phase: ApiProjectPhase,
    count: number,
): ApiProjectWithPhases {
    if ( !project.phases ) {
        throw new Error( 'Ensure phases are included.' );
    }

    // We modify the included API resource under the hood.
    // This is not ideal. We might want to load the project phases instead.
    const phases = project.phases.filter( ( { uuid } ) => uuid !== phase.uuid );

    phases.push( { ...phase, count } );

    return { ...project, phases };
}

function updateActions(
    state: State,
    projectUuid: string,
    entryUuid: string,
    action: ApiProjectEntryAction,
) {
    // As of now, the API does not send the user as recursively included
    // resource within the included project-entry action.
    // We however know that it is the current user.
    const authStore = useAuthStore();

    // We clone the user, in order not to mess with its reactivity.
    const user = Object.create( authStore.user );

    const newAction = {
        ...action,
        collaborator: user,
    } as ApiProjectEntryAction;

    if ( !state.actions?.[projectUuid]?.[entryUuid] ) {
        Vue.set( state.actions, projectUuid, {} as Record<string, ApiProjectEntryAction[]> );
        Vue.set( state.actions[projectUuid], entryUuid, [] as ApiProjectEntryAction[] );
    }

    state.actions[projectUuid][entryUuid].push( newAction );
}

// endregion

if ( import.meta.hot ) {
    import.meta.hot.accept( acceptHMRUpdate( useProjectEntriesStore, import.meta.hot ) );
}
