/**
 * Logo exports the current logo of a company based on its name.
 *
 * @export
 * @class Logo
 */

import { hashFromString, placeholderLetters } from '@main/utilities/formatting';

type CompanyAvatarColor = {
    background: string;
    text: string;
};

type CompanyAvatarColors = Record<string, CompanyAvatarColor>;

const companyAvatarColors: CompanyAvatarColors = {
    'variant-one': { background: '#F3B1AF', text: '#961915' },
    'variant-two': { background: '#F8D8AB', text: '#B96C00' },
    'variant-three': { background: '#FDFFBE', text: '#7B7E00' },
    'variant-four': { background: '#D5FDC5', text: '#5C9745' },
    'variant-five': { background: '#B0F4FD', text: '#008294' },
    'variant-six': { background: '#A7C3FA', text: '#0038A6' },
    'variant-seven': { background: '#BBB2F9', text: '#12008B' },
    'variant-eight': { background: '#F6C8FB', text: '#750082' },
};

export class Logo {
    protected readonly name: string | undefined;

    public constructor( name: string | undefined ) {
        this.name = name;
    }

    private colors() {
        if ( !this.name ) {
            return companyAvatarColors['variant-one'];
        }
        return colorFromNameHash( this.name[0] );
    }

    public textColor() {
        return this.colors().text;
    }

    public backgroundColor() {
        return this.colors().background;
    }

    public placeholderLetters() {
        if ( !this.name ) {
            return '';
        }
        return placeholderLetters( this.name );
    }
}

export function colorFromNameHash( name: string ) {
    const hash = hashFromString( name );

    const index = hash % Object.keys( companyAvatarColors ).length;
    const key = Object.keys( companyAvatarColors )[index];

    return companyAvatarColors[key];
}
