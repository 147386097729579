import { context } from '@main/api';
import type {
    ContextRequestOptions,
    ListOptions,
    PaginationResult,
} from '@main/api/plugin/types/api';
import type { DefaultMeta, ResourceObject } from '@main/api/plugin/types/jsonApi';
import type {
    ApiCompanySummary,
    KeywordCategory,
    ShipmentStatistics,
} from '@main/api/resources/companies';
import type { DebugFetchResults } from '@main/api/resources/debug';
import type { ApiProjectEntry, ApiProjectEntryMeta } from '@main/api/resources/projects';
import { extractLink, hydrateRelationships, serializeOptions } from '@main/utilities/api';
import type { ResourcePayload } from '@main/utilities/types';

// region Search Query Resource

export type ApiSearchQuery = {
    readonly createdAt: string;
    readonly deletedAt: string;
    readonly updatedAt: string;
    readonly uuid: string;

    // mutable
    aggregations: AggregatableField[];
    conditions: SearchCondition[];
    filters: SearchFilter[];
    minimumMatches?: number;

    previousQueryUuid: string | null;

    // includes
    readonly customerFilterCompanies?: ApiCompanySummary[];
};

export type SearchHistoryQuery = {
    contextKeywords?: string;
    createdAt: string;
    productKeywords: string;
    properties: HistoryQueryProperties;
    requiredKeywords?: string;
    uuid: string;
};

export type HistoryQueryProperties = {
    active: boolean;
    default: boolean;
};

export type NewApiSearchQuery = ResourcePayload<ApiSearchQuery>;

export const ApiSearchQueryDefaultAggregations: AggregatableField[] = [
    'categories',
    'certificates',
    'classifications',
    'continents',
    'countries',
    'customers',
    'employees',
];

export const ApiSearchQueryDebugAggregations: AggregatableField[] = [
    'taxonomyCommodities',
    'taxonomySegments',
    'taxonomyClasses',
    'taxonomyFamilies',
];

export type Aggregation = {
    readonly key: string;
    readonly label: string | undefined;
} & Record<string, unknown>;

export type CountedAggregation = {
    readonly value: number;
} & Aggregation;

export type CustomerAggregation = {
    readonly data: {
        readonly locality: string;
        readonly country: string;
    };
} & CountedAggregation;

export type AggregatableField =
    | 'categories'
    | 'certificates'
    | 'classifications'
    | 'continents'
    | 'countries'
    | 'customers'
    | 'employees'
    | 'products'
    | 'regions'
    | 'tradingAreas'
    | 'taxonomyCommodities'
    | 'taxonomySegments'
    | 'taxonomyClasses'
    | 'taxonomyFamilies';

export type NestedValueFilterKey = 'continents' | 'countries' | 'regions';

export type SearchFilterValueMap = {
    category: string;
    certificate: string;
    classification: string;
    competitor: string;
    customer: string;
    employees: string;
    name: string;
    product: string;
    tradingArea: string;
    taxonomyCommodity: string;
    taxonomySegment: string;
    taxonomyClass: string;
    taxonomyFamily: string;

    hasShipments: boolean;
    hasWebsite: boolean;

    geoBoundingBox: [
        number, // top latitude
        number, // top longitude
        number, // bottom latitude
        number, // bottom longitude
    ];

    geoRegion: {
        continents?: string[];
        countries?: string[];
        regions?: string[];
    };
};

export type SearchFilter<
    F extends FilterField = FilterField,
    U extends SearchFilterValueMap[F] = SearchFilterValueMap[F],
> = {
    readonly field: F;
    readonly operator?: F extends OrdinalFilterField
        ? 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte'
        : 'eq' | 'neq';
} & ( F extends SingleValueFilterField
    ? { readonly value: U; }
    : { readonly values: U[]; } | { readonly value: U; } );

export type FilterField = SingleValueFilterField | NominalFilterField | OrdinalFilterField;

export type SingleValueFilterField = 'geoBoundingBox' | 'geoRegion' | 'hasShipments' | 'hasWebsite';

export type OrdinalFilterField = 'employees';

export type NominalFilterField =
    | 'category'
    | 'certificate'
    | 'classification'
    | 'competitor'
    | 'customer'
    | 'name'
    | 'product'
    | 'tradingArea'
    | 'taxonomyCommodity'
    | 'taxonomySegment'
    | 'taxonomyClass'
    | 'taxonomyFamily';

export const filterFields: FilterField[] = [
    'category',
    'certificate',
    'classification',
    'competitor',
    'customer',
    'employees',
    'geoBoundingBox',
    'geoRegion',
    'hasShipments',
    'hasWebsite',
    'name',
    'product',
    'tradingArea',
    'taxonomyCommodity',
    'taxonomySegment',
    'taxonomyClass',
    'taxonomyFamily',
];

export type SearchConditionType =
    // Main search form
    | 'product'
    | 'context'

    // Keyword filtering, supposed to be used as required groups
    | 'keyword-filter'
    | 'certificate-filter'
    | 'classification-filter'

    // More
    | 'service'
    | 'generic';

export type SearchConditionKeyword = {
    readonly term: string;
    readonly weight?: number;
    readonly canonical?: boolean;
};

export type SearchCondition = {
    readonly type: SearchConditionType;
    readonly required: boolean | number;
    readonly keywords: SearchConditionKeyword[];
};

// endregion

// region Search Result Resource

export type ApiSearchResult = {
    // Search related attributes
    readonly uuid: string;
    readonly aggregatedScore: number;
    readonly aggregatedMatchedKeywords: string[];

    // includes / always present
    readonly topMatch: ApiSearchResultCompany;
    readonly additionalMatches: ApiSearchResultCompany[];

    // included only if requested
    readonly projectEntries?: ApiProjectEntry[];
};

export type ApiSearchResultCompany = {
    //
} & ApiCompanySummary &
    IncludedCompanyMeta;

type IncludedCompany = ResourceObject<ApiCompanySummary, IncludedCompanyMeta>;

type IncludedCompanyMeta = {
    // Search related attributes
    readonly score: number;
    readonly matchedKeywords: string[];

    // only included if user has debug feature enabled
    readonly debug?: {
        readonly elasticScore: number;
        readonly extendedMatchedKeywords: Record<string, ExtendedMatchedKeyword[]>;
        readonly keywordsFocusScore: number;
        readonly keywordsFocus: Record<string, number>;
        readonly shipmentStatistics?: ShipmentStatistics | null;
        readonly categories: KeywordCategory[];
        readonly size: {
            bucket: {
                average: number;
                max: number;
            };
            linear: {
                average: number;
                max: number;
            };
        };
        readonly taxonomy: Taxonomy;
    };
};

export type SearchResultsAugmentationMeta = Record<string, unknown>;

type SearchResultsMeta = DefaultMeta & {
    aggregations: Partial<Record<AggregatableField, CountedAggregation[]>>;

    augmentations?: SearchResultsAugmentationMeta;

    // Total number of available results, which corresponds to DefaultMeta.total
    // and is added here for clarity.
    availableResultsCount: number;

    // Total number of companies in all available results.
    // That is the primary results including all grouped ones.
    availableCompaniesCount: number;

    // Total number of ES hits.
    hitCount: number;
};

export type FetchApiSearchResultsReturn = {
    readonly results: ApiSearchResult[];
    readonly aggregations: Partial<Record<AggregatableField, CountedAggregation[]>>;
    readonly augmentations?: SearchResultsAugmentationMeta;

    // See SearchResultsMeta
    availableResultsCount: number;
    availableCompaniesCount: number;
    hitCount: number;
} & PaginationResult;

export type FetchApiProjectEntryResultsReturn = {
    readonly results: ApiProjectEntry[];
    readonly aggregations: Partial<Record<AggregatableField, CountedAggregation[]>>;
} & PaginationResult;

export type ExtendedMatchedKeyword = {
    readonly augmented: boolean;
    readonly aspect: string;
    readonly aspectVersion: string;
    readonly details: string;
    readonly keyword: string;
};

export type Taxonomy = {
    readonly sourceOnly: {
        readonly commodities: TaxonomyItem[];
        readonly classes: TaxonomyItem[];
        readonly families: TaxonomyItem[];
        readonly segments: TaxonomyItem[];
    };
};

export type TaxonomyItem = {
    readonly code: string;
    readonly title: string;
    readonly relevance: number;
    readonly count: number;
};

export type ApiKeywordCompletion = {
    readonly suggestion: string;
    readonly phrase: string;
    readonly score: number;
};

// endregion

// region Debug
export type Explanation = {
    index: string;
    _id: string;
    matched: boolean;
    explanation: ExplanationNode;
};

export type ExplanationNode = {
    value: number;
    description: string;
    details: ExplanationNode[];
};

// endregion

const { all, single, create, destroy, put } = context;

export async function createQuery( data: NewApiSearchQuery, project?: string ) {
    if ( !project ) {
        throw new Error(
            'Queries outside project context are not ' + 'supported in the backend yet.',
        );
    }

    const url = project ? `projects/${project}/queries` : 'queries';
    const { attributes } = await create<ApiSearchQuery>( url, data, {
        returnResource: true,
        invalidates: [new RegExp( `/queries.*` ), new RegExp( `/projects/${project}/queries.*` )],
    } );

    // customerFilterCompanies are not included, as creating a resource
    // does not support the concept of including resources in
    // the response.
    return attributes;
}

export function deleteQuery( query: string, project?: string ) {
    const url = project ? `projects/${project}/queries/${query}` : `queries/${query}`;

    return destroy( url, {
        invalidates: ['queries'],
    } );
}

export async function updateDefaultQuery( project: string, query: string ) {
    await put( `projects/${project}/queries/default`, {
        data: {
            type: 'searchQuery',
            id: query,
        },
    } );
}

export async function fetchQueries( project?: string, options?: ListOptions<ApiSearchQuery> ) {
    const url = project ? `projects/${project}/queries` : `queries`;
    const { data } = await all<ApiSearchQuery>( url, {
        searchParams: options ? serializeOptions( options ) : undefined,
    } );

    return data.map( ( { attributes } ) => attributes );
}

export async function fetchQuery( query: string, project?: string ): Promise<ApiSearchQuery> {
    const url = project ? `projects/${project}/queries/${query}` : `queries/${query}`;

    const { attributes, relationships, included } = await single<ApiSearchQuery>( url );

    return {
        ...attributes,
        ...hydrateRelationships( relationships, included ),
    };
}

export async function fetchKeywordCompletions(
    term: string,
    amount?: number,
    options?: ContextRequestOptions,
) {
    const { data } = await all<ApiKeywordCompletion>( 'suggestions/completions', {
        ...options,
        bypass: true,
        searchParams: {
            term,
            amount: amount ?? 10,
        },
        suppressErrorHandling: true,
        timeout: import.meta.env.PROD ? 3000 : undefined,
    } );

    return data.map( ( { attributes } ) => attributes );
}

/**
 * Fetches keywords for a given keyword from OpenAI.
 */
export async function fetchKeywordSuggestions( keyword: string, amount: number ) {
    const requestKeyword = encodeURIComponent( keyword.replace( '/', ' ' ) );

    const { data } = await all<{ suggestions: string[]; }>(
        `suggestions/keywords/${requestKeyword}`,
        {
            searchParams: {
                amount,
            },

            // Do not cache the suggestions, because each call to this
            // endpoint with identical keywords may still produce
            // different results.
            bypass: true,
        },
    );

    return data.flatMap( ( { attributes: { suggestions } } ) => suggestions );
}

/**
 * Fetches related keywords for a given set of keywords. The keywords
 * returned are computed based on aggregation buckets for an ES query
 * generated using the input keywords. Hence, we return an aggregation.
 *
 * @param keywords
 * @param amount
 * @param options
 */
export async function fetchRelatedKeywords(
    keywords: string[],
    amount = 10,
    options?: ContextRequestOptions,
) {
    const { attributes } = await single<{ buckets: CountedAggregation[]; }>( 'suggestions/related', {
        ...options,
        bypass: true,
        // suppressErrorHandling: true,
        searchParams: {
            keywords,
            amount,
        },
    } );

    return attributes.buckets;
}

/**
 * Fetches the company results for a given search query.
 * @param query
 * @param project
 * @param page
 * @param perPage
 * @param debug
 */
export async function fetchResults(
    query: string,
    project: string | null,
    page?: number,
    perPage?: number,
    debug: DebugFetchResults | null = null,
): Promise<FetchApiSearchResultsReturn> {
    const url = project
        ? `projects/${project}/queries/${query}/results`
        : `queries/${query}/results`;

    const { data, meta, included } = await all<
        ApiSearchResult,
        IncludedCompany | ApiProjectEntry,
        SearchResultsMeta
    >( url, {
        searchParams: {
            page: page ?? 1,
            per_page: perPage ?? 30,
            ...( debug && debug ),
            ...( !!project && { include: ['projectEntries'] } ),

            // TODO: To enable XDebug tracing, set the
            //       VITE_XDEBUG_ENABLED variable either to "true"
            //       to enable it for all requests or to e.g.
            //       "/results" to enable it only for requests
            //       with an endpoint containing "/results".
        },
        retry: {
            afterStatusCodes: [202, 413, 429, 503],
            limit: 20,
            statusCodes: [202, 413, 503],
        },
    } );

    if ( !included ) {
        throw new Error( 'Expected included company summaries.' );
    }

    // Standard search results
    const results = data.map( ( { attributes, relationships } ): ApiSearchResult => {
        const result: ApiSearchResult = {
            ...attributes,
            ...hydrateRelationships<IncludedCompany | ApiProjectEntry>( relationships, included ),
        };

        if ( !result.topMatch ) {
            throw new Error( 'Expected top matching company summary.' );
        }

        // Grab the logo and profile urls from the included top and
        // additionally matched companies and apply them to the readonly
        // company resources.
        const includedCompany = included.find( ( { id } ) => id === result.topMatch.uuid );

        if ( !includedCompany ) {
            throw new Error( 'Expected included company summary.' );
        }

        const topMatch: ApiSearchResultCompany = {
            ...result.topMatch,
            logoUrl: extractLink( includedCompany, 'logo' ),
            profileUrl: extractLink( includedCompany, 'profile' ),
        };

        // Now the same for additional matches.
        const additionalMatches = ( result.additionalMatches || [] ).map(
            ( match ): ApiSearchResultCompany => {
                const data = included.find( ( { id } ) => id === match.uuid );

                if ( !data ) {
                    throw new Error( 'Expected included company summary.' );
                }

                return {
                    ...match,
                    logoUrl: extractLink( data, 'logo' ),
                    profileUrl: extractLink( data, 'profile' ),
                };
            },
        );
        const results = [topMatch, ...additionalMatches];

        // Hydrate the nested project entries.
        const projectEntries = ( result.projectEntries ?? [] ).map( ( entry ): ApiProjectEntry => {
            const company = results.find( ( { uuid } ) => uuid === entry.companyUuid );

            if ( !company ) {
                throw new Error( 'Expected included company summary.' );
            }

            return {
                ...entry,
                company,
                uuid: entry.companyUuid,
            };
        } );

        return {
            ...result,
            additionalMatches,
            projectEntries,
        };
    } );

    // As opposed to other pagination endpoints, this one is capped
    // and does not allow for iterating all results.
    // The hitCount tells us, how many individual results
    // were actually found.
    const availableResultsCount = meta?.availableResultsCount ?? 0;
    const availableCompaniesCount = meta?.availableCompaniesCount ?? 0;
    const hitCount = meta?.hitCount ?? 0;
    const currentPage = meta?.currentPage ?? 0;
    const pageSize = meta?.perPage ?? 0;
    const lastPage = meta?.lastPage ?? 0;
    const aggregations = meta?.aggregations ?? {};
    const augmentations = meta?.augmentations ?? {};

    return {
        aggregations,
        augmentations,
        availableCompaniesCount,
        availableResultsCount,
        currentPage,
        hitCount,
        lastPage,
        perPage: pageSize,
        results,
        total: availableResultsCount,
    };
}

/**
 * Fetches the requested aggregation for a given query,
 * e.g. for suggesting aggregated keywords.
 */
export async function fetchResultsAggregation(
    project: string,
    query: string,
    keywords: string[],
    aggregation: AggregatableField,
    amount = 10,
    options?: ContextRequestOptions,
) {
    const { attributes } = await single<{ buckets: CountedAggregation[]; }>(
        `projects/${project}/queries/${query}/aggregation/${aggregation}`,
        {
            ...options,
            bypass: true,
            searchParams: {
                aggregation,
                amount,
                keywords,
            },
            suppressErrorHandling: true,
        },
    );

    return attributes.buckets;
}

export async function fetchProjectEntries(
    query: string,
    project: string,
    page?: number,
    perPage?: number,
    debug: DebugFetchResults | null = null,
): Promise<FetchApiProjectEntryResultsReturn> {
    const { data, included, meta } = await all<
        ApiProjectEntry,
        ApiCompanySummary,
        SearchResultsMeta & ApiProjectEntryMeta
    >( `projects/${project}/queries/${query}/entries`, {
        searchParams: {
            page: page ?? 1,
            per_page: perPage ?? 30,
            ...( debug && debug ),
        },
    } );

    const results = data.map( ( { attributes, meta } ): ApiProjectEntry => {
        // Find included resource and merge it into the entry
        const company = included?.find(
            ( { attributes: { uuid } } ) => uuid === attributes.companyUuid,
        );

        if ( !company ) {
            throw new Error( 'Expected included company summary.' );
        }

        return {
            ...attributes,
            company: {
                ...company.attributes,
                logoUrl: extractLink( company, 'logo' ),
                profileUrl: extractLink( company, 'profile' ),
            },
            rfxState: meta!.rfxState,
            uuid: attributes.companyUuid,
        };
    } );

    const total = meta?.total ?? 0;
    const currentPage = meta?.currentPage ?? 0;
    const pageSize = meta?.perPage ?? 0;
    const lastPage = meta?.lastPage ?? 0;
    const aggregations = meta?.aggregations ?? {};

    return {
        aggregations,
        currentPage,
        lastPage,
        perPage: pageSize,
        results,
        total,
    };
}

export async function explainQuery(
    project: string,
    query: string,
    company: string,
    debug: DebugFetchResults,
): Promise<Explanation> {
    const { attributes } = await single<{ explanation: Explanation; }>(
        `projects/${project}/queries/${query}/explain/${company}`,
        {
            searchParams: {
                ...( debug && debug ),
                includeDebugData: 0,
            },
        },
    );

    return attributes.explanation;
}
